@keyframes anim-mail {
  50% {
    -webkit-transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
    transform: translate3d(0, 50%, 0) rotate3d(0, 0, 1, 10deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
    transform: translate3d(0, 0%, 0) rotate3d(0, 0, 1, 0deg);
  }
}

html {
  height: 100%;
}

body {
  font-family: $font-body;
  font-size: 15px;
  line-height: 1.4;
  color: $text-color;
  background: #fff;
  @include breakpoint($laptop) {
    font-size: 16px;
    line-height: 1.8;
  }
}

.container {
  padding: $container-gutter;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  @include breakpoint($tablet) {
    max-width: 768px;
  }
  @include breakpoint($laptop) {
    max-width: 992px;
    padding: 0 2.5rem;
  }
  @include breakpoint($desktop) {
    max-width: 1200px;
    padding: 0 2.5rem;
  }
}

.push-3 {
  margin-left: 0;
  @include breakpoint($laptop) {
    margin-left: 30%;
  }
}

.col-3 {
  clear: both;
  @include breakpoint($laptop) {
    clear: inherit;
    position: relative;
    width: 25%;
    float: left;
  }
}

.col-5 {
  @include breakpoint($laptop) {
    position: relative;
    width: 41%;
    float: left;
  }
}

.col-6 {
  @include breakpoint($laptop) {
    position: relative;
    width: 50%;
    float: left;
  }
}

.pull-5 {
  @include breakpoint($laptop) {
    right: 41%;
    margin-left: 9%;
  }
}

.pull-3 {
  @include breakpoint($laptop) {
    right: 30%;
  }
}

.push-5 {
  @include breakpoint($laptop) {
    left: 50%;
  }
}

.pull-6 {
  @include breakpoint($laptop) {
    right: 50%
  }
}

.push-6 {
  @include breakpoint($laptop) {
    left: 50%;
  }
}

h2 {
  font-family: $font-title;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1;
  @include breakpoint($laptop) {
    font-size: 84px;
    margin-bottom: 48px;
  }
  @include breakpoint($desktop) {
    font-size: 106px;
    line-height: 1.1;
  }
  span {
    @include breakpoint($laptop) {
      font-size: 50px;
    }
    @include breakpoint($desktop) {
      font-size: 80px;
    }
  }
}

  img {
    max-width: 100%;
    height: auto;
    &.reveal {
      visibility: hidden;
    }
  }

strong {
  font-weight: 700;
}

.curved-pattern {
  content: '';
  background: url("/assets/src/images/curved-pattern.svg") no-repeat;
  background-size: contain;
  width: 60px;
  height: 6px;
  display: inline-block;
}

.btn {
  &.btn-default {
    font-family: $font-body;
    border: 1px solid #fff;
    letter-spacing: 2px;
    padding: 12px 18px;
    text-transform: uppercase;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    margin-top: 10px;
    font-size: 14px;
    @include breakpoint($laptop) {
      margin-top: 40px;
      padding: 20px 30px;
      font-size: 16px;
    }
  }

  &.btn-dark {
    font-family: $font-body;
    border: 1px solid #abbcab;
    letter-spacing: 2px;
    padding: 12px 18px;
    text-transform: uppercase;
    display: inline-block;
    color: #abbcab;
    text-decoration: none;
    margin-top: 10px;
    font-size: 14px;
    @include breakpoint($laptop) {
      margin-top: 40px;
      padding: 20px 30px;
      font-size: 16px;
    }
      &:hover {
        background-color: #abbcab;
        color: #fff;
        transition: 0.2s;
      }
  }
}

body {
  height: 100%;
  #page {
    height: 100%;
    .content-404 {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .content-text {
        text-align: center;
          h1 {
            font-size: 70px;
          }
          h2 {
            font-size: 40px;
            margin-bottom: 30px;
              &:after {
                content: '';
                margin: 0 auto;
                width: 30px;
                height: 2px;
                background-color: $brand-kaki;
                position: relative;
                display: block;
                margin-top: 30px;
              }
          }
      }
    }
  }
}

.btn {
  &.email {
    position: relative;
    overflow: hidden;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  &.email::before {
    content: '';
    position: absolute;
    left: -50%;
    width: 200%;
    height: 200%;
    background: #37474f;
    top: -50%;
    z-index: 1;
    -webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
    transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -10deg);
  }
  &.email::before {
    -webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
    transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 10deg);
  }
  &.email > span {
    display: block;
  }
  &.email img {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    padding: 0.72rem 4.6rem;
    color: #fff;
    text-align: center;
    width: 16px;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0 );
    @include breakpoint($laptop) {
      padding: 1.3rem 5.7rem;
      width: 20px;
    }
  }
  &.email > span,
  &.email img {
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }
  &.email:hover::before {
    -webkit-animation: anim-naira-1 0.3s forwards ease-in;
    animation: anim-naira-1 0.3s forwards ease-in;
  }
  &.email:hover::before {
    -webkit-animation: anim-mail 0.3s forwards ease-in;
    animation: anim-mail 0.3s forwards ease-in;
  }

  &.email:hover > span {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  &.email:hover img {
    transform: translate3d(0, 0, 0);
  }
}

section {
  clear: both;
  float: left;
  width: 100%;
  &:nth-child(odd){
    padding: 50px 0;
    @include breakpoint($laptop) {
      padding: 100px 0;
    }
  }
  &:last-child {
    padding: 50px 0;
    @include breakpoint($laptop) {
      padding: 100px 0;
    }
  }
  &.header {
    text-align: center;
    padding: 30px 0;
    @include breakpoint($laptop) {
      padding: 60px 0;
    }
    h1 {
      font-size: 19px;
      font-family: $font-body;
      color: #718979;
      text-transform: uppercase;
      line-height: 1.5;
      @include breakpoint($laptop) {
        font-size: 26px;
      }
      span {
        display: block;
        text-transform: none;
        font-size: 15px;
        font-family: $font-title;
        @include breakpoint($laptop) {
          font-size: 20px;
        }
      }
    }
  }

  &.mission {
    position: relative;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
      @include breakpoint($laptop) {
        height: 500px;
      }
        .content-img {
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
            img {
              object-position: 50% 0%;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
        }
        .content-text {
          width: 100%;
          text-align: center;
          color: #fff;
            h2 {
              font-family: $font-title;
              font-size: 35px;
              letter-spacing: 1px;
              z-index: 10;
              position: relative;
              @include breakpoint($laptop) {
                font-size: 58px;
                letter-spacing: 2px;
              }
            }
        }
    }

      &.compat-object-fit {
          background-size: cover;
          background-position: top center;
        .content-img {
          img {
            opacity: 0 !important;
          }
        }
        }
  }

  &.intro {
    font-family: $font-title;
    font-size: 20px;
    line-height: 1.4;
    @include breakpoint($laptop) {
      font-size: 26px;
      line-height: 1.6;
    }

    a {
      color: $text-color;
      transition: 0.2s;
        &:hover {
          color: $brand-kaki;
          transition: 0.2s;
        }
    }
  }

  &.formations {
    .title {
      position: relative;
      margin-bottom: 30px;
      margin-left: 72px;
      @include breakpoint($laptop) {
        margin-left: 0px;
        margin-bottom: 56px;
      }
      .curved-pattern {
        position: absolute;
        left: -70px;
        top: 8px;
      }
      h3 {
        display: inline-block;
        text-transform: uppercase;
      }
    }

    ul {
      padding-left: 15px;
      @include breakpoint($laptop) {
        padding-left: 0px;
      }
      li {
        list-style: disc;
        padding-bottom: 10px;
        @include breakpoint($laptop) {
          list-style: none;
        }
      }
    }
  }

  &.sexotherapie {
    padding-bottom: 0;
    .container {
      position: relative;
      &:after {
        background-color: $gray-light;
        width: 100%;
        height: 52%;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
        @include breakpoint($laptop) {
          content: '';
        }
      }
    }
    .content-text {
      margin-top: 20px;
      &:after {
        content: '';
        background: url("/assets/src/images/curved-pattern.svg") no-repeat;
        background-size: contain;
        width: 60px;
        height: 6px;
        margin-top: 34px;
        margin-bottom: 30px;
        display: inline-block;
        @include breakpoint($laptop) {
          margin-left: 60px;
        }
      }
      p {
        @include breakpoint($laptop) {
          padding-left: 60px;
        }
      }
    }

    .col-6 {
      .img {
        @include breakpoint($laptop) {
          padding-right: 2rem;
          margin-left: -10rem;
        }
      }
    }

    .center {
      clear: both;
      text-align: center;
      display: none;
      @include breakpoint($laptop) {
        display: block;
      }
        img {
          margin-top: -12rem;
          position: relative;
          z-index: 10;
        }
    }
  }

  &.therapie-couple {
    margin-top: -30px;
    @include breakpoint($laptop) {
      margin-top: -50px;
    }
    .container {
      position: relative;
      padding-top: 80px;
      @include breakpoint($laptop) {
        padding-top: 130px;
      }
      &:after {
        content: '';
        background-color: $gray-light;
        width: 100%;
        height: 74%;
        top: 0;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
      }
    }
    .title {
      position: relative;
      z-index: 12;
      h2 {
        color: $brand-kaki;
          &:after {
            content: '';
            background: url("/assets/src/images/curved-pattern.svg") no-repeat;
            background-size: contain;
            width: 60px;
            height: 6px;
            margin-top: 30px;
            display: block;
          }
      }
    }

    .content-text {
      margin-top: 0px;
      position: relative;
      z-index: 10;
      @include breakpoint($laptop) {
        padding: 0 40px;
        margin-top: 164px;
        text-align: right;
      }
    }

    .img {
      @include breakpoint($laptop) {
        text-align: right;
      }
      @media (min-width: 1415px) {
        margin-right: -100px;
      }
        img {
          position: relative;
          z-index: 11;
          margin: 30px 0 0 0;
          @include breakpoint($laptop) {
            margin-bottom: 0;
            width: 44%;
            margin-top: -96px;
          }
          @media (min-width: 1415px) {
            z-index: 1;
          }
        }
    }
  }

  &.therapie-individuelle {
    position: relative;
    z-index: 10;
    @include breakpoint($laptop) {
      margin-top: -190px;
    }
    .content-text {
      padding-right: 0px;
      @include breakpoint($laptop) {
        padding-right: 228px;
      }
      @include breakpoint($tv) {
        padding-right: 328px;
        text-align: right;
      }
      p {
        position: relative;
      }

      img {
        margin-top: 30px;
      }

      h2 {
        text-align: left;
      }
    }

      .img {
        position: relative;
        z-index: -1;
        margin: 30px 0 0 0;
        @include breakpoint($laptop) {
          margin-left: -12rem;
            img {
              margin: 0 0 0 0;
            }
        }
      }
  }


  &.therapie-familiale {
    .title {
      .content-text {
        position: relative;
        z-index: 10;
        @include breakpoint($laptop) {
          padding-left: 100px;
        }
        h2 {
          color: $brand-kaki;
        }
        p {
          &:after {
            content: '';
            background: url("/assets/src/images/curved-pattern.svg") no-repeat;
            background-size: contain;
            width: 60px;
            height: 6px;
            margin-top: 34px;
            display: block;
          }
        }
      }
    }

    img {
      margin: 30px 0 0 0;
      @include breakpoint($laptop) {
        margin: 100px 0 0 0;
      }
    }
  }

  &.logos {
    background-color: #cdd7cd;
    position: relative;
    z-index: -1;
    margin-top: -30px;
    @include breakpoint($laptop) {
      margin-top: -150px;
    }
      ul {
        display: flex;
        align-items: flex-end;
        li {
          display: inline-block;
          margin-right: 15px;
          margin-left: 15px;
          @include breakpoint($laptop) {
            margin-right: 70px;
            margin-left: 40px;
          }
            img {
              width: 120px;
              @include breakpoint($laptop) {
                width: auto;
              }
            }
        }
      }
  }

  &.infomations {
    h1 {
      font-size: 19px;
      font-family: $font-body;
      color: #718979;
      text-transform: uppercase;
      margin-bottom: 40px;
      line-height: 1.3;
      @include breakpoint($laptop) {
        margin-bottom: 90px;
        font-size: 26px;
      }
      span {
        display: block;
        text-transform: none;
        font-size: 15px;
        font-family: $font-title;
        margin-top: 6px;
        @include breakpoint($laptop) {
          font-size: 20px;
        }
      }
    }

    h4 {
      font-family: $font-body;
      text-transform: uppercase;
      font-size: 13px;
      margin-bottom: 6px;
      @include breakpoint($laptop) {
        font-size: 16px;
        margin-bottom: 0px;
      }
    }

    p {
      font-family: $font-title;
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 20px;
      @include breakpoint($laptop) {
        font-size: 40px;
        margin-bottom: 25px;
      }
    }

    .schedule {
      margin-bottom: 40px;
      @include breakpoint($laptop) {
        margin-bottom: 70px;
      }
    }

    .contact {
        a {
          color: $text-color;
          font-family: $font-title;
          font-weight: normal;
          font-size: 24px;
          display: block;
          text-decoration: inherit;
          position: relative;
          margin-bottom: 10px;
          line-height: 1.4;
          @include breakpoint($laptop) {
            font-size: 40px;
          }
            &.mail {
              display: inline-block;
              transition: 0.2s;
              &:after {
                content: '';
                width: 100%;
                height: 1px;
                bottom: -4px;
                left: 0;
                position: absolute;
                display: block;
                background-color: $brand-kaki;
                transition: 0.2s;
                @include breakpoint($laptop) {
                  height: 2px;
                }
              }
              &:hover {
                color: $brand-kaki;
                transition: 0.2s;
                &:after {
                  height: 3px;
                  transition: 0.2s;
                }
              }
            }
        }
    }
  }
}



