$container-width: 1332px;
$container-gutter: 0 20px;
$grid-gutter-width: 25px;

//Hamburger
$hamburger-width: 35px;                    // The width of the button area
$hamburger-height: 25px;                   // The height of the button area
$bar-thickness: 3px;                    // The thickness of the button bars
$hamburger-pad: 0px;                      // The left/right padding between button area and bars.
$hamburger-bar-space: 6px;                // The spacing between button bars
$hamburger-transistion-duration: 0.2s;     // The transition duration