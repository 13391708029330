$mobile:       ( max:  767px );
$tablet:       ( min:  768px );
$laptop:       ( min:  992px );
$desktop:      ( min: 1200px );
$tv:           ( min: 1640px );

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */

/*
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );
$md-only: ( min: map-get($md, min), max: map-get($lg, min) - 1 );
*/
